
import { defineComponent, reactive, toRefs } from "vue";
export default defineComponent({
  props: {
    currentItem: Object,
    loading: Boolean,
  },
  setup() {
    let state = reactive({});
    return { ...toRefs(state) };
  },
});
