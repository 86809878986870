import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27ef9abf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar" }
const _hoisted_2 = { class: "titles" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "subTitle" }
const _hoisted_5 = { class: "sidebar-list" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.subTitle), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["sidebar-item", { active: _ctx.activeIndex == index }]),
          onClick: ($event: any) => (_ctx.sidebarclick(item, index))
        }, [
          _createElementVNode("span", null, _toDisplayString(item.name), 1)
        ], 10, _hoisted_6))
      }), 256))
    ])
  ]))
}