
import { defineComponent, reactive, toRefs, watch } from "vue";
export default defineComponent({
  props: {
    dataSource: Array,
    title: String,
    subTitle: String,
    activeIndex: Number,
  },
  setup(props, { emit }) {
    interface propsType {
      data?: Array<any>;
    }
    let state: propsType = reactive({
      data: props.dataSource,
    });

    watch(props, (props, prevProps) => {
      state.data = props?.dataSource;
    });

    const sidebarclick = (item: any, index: any) => {
      emit("sidebarclick", item);
    };

    return { ...toRefs(state), sidebarclick };
  },
});
