import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f85bb00c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "details",
  background: "#fff"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "info"
}
const _hoisted_4 = { class: "info-item" }
const _hoisted_5 = { class: "info-item" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.currentItem?.title), 1),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createTextVNode("发布时间："),
            _createElementVNode("span", null, _toDisplayString(_ctx.currentItem?.time), 1)
          ]),
          _createElementVNode("span", _hoisted_5, [
            _createTextVNode("阅读量："),
            _createElementVNode("span", null, _toDisplayString(_ctx.currentItem?.hits) + "次 ", 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("p", {
      innerHTML: _ctx.currentItem?.content
    }, null, 8, _hoisted_6)
  ])), [
    [_directive_loading, _ctx.loading]
  ])
}